import { Network } from '@/lib/models';

/**
 * Request Params
 */
export interface RequestParams {
  id: number;
  chainId: string;
  method: string;
  params: any[];
}

/**
 * Session Metadata
 */
export interface SessionMetadata {
  name?: string;
  description?: string;
  url?: string;
  icons?: string[];
}

/**
 * List of disallowed chain IDs for Web3 Access.
 */
export const unsupportedWeb3AccessNetworks = new Set([Network.Type.Bitcoin, Network.Type.TestnetBitcoin]);

/**
 * List of disallowed chain IDs for BlockAid.
 */
export const unsupportedBlockAidNetworks = new Set([
  ...unsupportedWeb3AccessNetworks,
  Network.Type.Fantom,
  Network.Type.Tron,
]);

/**
 * Signature Types
 */
export enum SignatureType {
  EIP712 = 'EIP712',
  EIP155 = 'EIP155',
}

/**
 * EIP712 Type
 */
export interface EIP712 {
  primaryType: string;
  domain: Record<string, unknown>;
  message: Record<string, unknown>;
  types: { [key: string]: { type: string; name: string }[] };
}

/**
 * Transaction object Type
 */
export type TransactionObject = {
  data: string;
  from: string;
  to: string;

  gas: string;

  gasLimit?: string;
  gasPrice?: string;
  value?: string;
  nonce?: string;
};

/**
 * Definition List Type
 */
export type DefinitionList = { title: string; description: string }[];

/**
 * Generic Sign Content Type
 */
export type GenericSignContent = string | DefinitionList;

/**
 * Raw Message Type
 */
export type RawMessage = string | EIP712;

/**
 * Generic Message Type
 */
export type GenericMessage = {
  type: 'generic-message';
  address: string;
  heading?: string;
  message: DefinitionList;
  rawMessage?: RawMessage;
  method: string;
};
